import {
  USERS_SELECT,
  LOADER,
  USERS_DETAIL,
  USER_UPDATE,
  USER_SEARCH,
  STATE_LIST,
  UPDATE_REGISTER_USERS,
  VERIFY_CAPTCHA_UPDATE_USER,
  PRINT_DATA_STORE,
  PDF_BUTTTON_LOADER,
  CHOLA_BUTTON_LOADING,
  EMD_BUTTON_LOADING,
  UPDATE_REGISTER_FORM_BUTTON_LOADING,
} from "../Action/ActionTypes";

import api from "../Config/Api";
import { message } from "antd";

// Chola Export EXCEL
export const CholaExportAction = () => {
  return (dispatch) => {
    dispatch(CholaButtonLoading(true));
    try {
      api.get("admin/cholaExcelExport").then(
        (response) => {
          let responseStatus = response?.data?.code;
          if (responseStatus === 200) {
            let datas = response?.data?.data?.cholaExcelFileUrl;
            let a = document.createElement("a");
            a.href = datas;
            a.download = "CholaMS MIS";
            a.click();
            dispatch(CholaButtonLoading(false));
          }
        },
        function (error) {
          dispatch(CholaButtonLoading(false));
          message.error("Something Went Wrong");
        }
      );
    } catch {
      dispatch(CholaButtonLoading(false));
      message.error("Something Went Wrong");
    }
  };
};

// EMD Export EXCEL
export const EmdExportAction = () => {
  return (dispatch) => {
    dispatch(EmdButtonLoading(true));
    try {
      api.get("admin/emdExcelExport").then(
        (response) => {
          let responseStatus = response?.data?.code;
          if (responseStatus === 200) {
            let datas = response?.data?.data?.emdExcelFileUrl;
            let a = document.createElement("a");
            a.href = datas;
            a.download = "GigSURE EMD";
            a.click();
            dispatch(EmdButtonLoading(false));
          }
        },
        function (error) {
          dispatch(EmdButtonLoading(false));
          message.error("Something Went Wrong");
        }
      );
    } catch {
      dispatch(EmdButtonLoading(false));
      message.error("Something Went Wrong");
    }
  };
};

// Select All Users
export const AllUserSelect = (page, pageSize) => {
  return (dispatch) => {
    dispatch(loader(true));

    try {
      const config = {
        params: {
          pageNumber: page,
          nPerPage: pageSize,
        },
      };

      api.get("user/list", config).then(
        (response) => {
          let data = response.data.data.list;
          let count = response.data.data.count;
          let responseStatus = response.data.code;

          let params = {
            count,
            data,
          };

          if (responseStatus === 200) {
            dispatch({
              type: USERS_SELECT,
              payload: params,
            });
            dispatch(loader(false));
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

export const UserSearchAction = (params) => {
  return (dispatch) => {
    dispatch(loader(true));

    try {
      api({
        url: "user/search",
        method: "get",
        data: params,
        params: params,
      }).then(
        (response) => {
          let responseStatus = response?.data?.code;
          let data = response?.data?.data?.list;
          let count = response?.data?.data?.count;

          let para = {
            count,
            data,
          };

          if (responseStatus === 200) {
            dispatch({
              type: USER_SEARCH,
              payload: para,
            });
            dispatch(loader(false));
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

//  Active InActive
export const ActiveInActiveAction = (params) => {
  let parameters = {
    user_id: params.user_id,
    status: params.actionPerform,
  };

  return (dispatch) => {
    dispatch(loader(true));
    try {
      api({
        url: "user/action",
        method: "post",
        data: parameters,
        params: parameters,
      }).then(
        (response) => {
          let message = response.data.message;
          let responseStatus = response.data.code;

          if (responseStatus === 200) {
            dispatch({
              type: USER_UPDATE,
              payload: message,
            });
            dispatch(loader(false));

            if (
              params.name === null &&
              params.policy_number === null &&
              params.status === null
            ) {
              dispatch(AllUserSelect(params.pageNumber, params.nPerPage));
            } else {
              dispatch(
                UserSearchAction({
                  name: params.name,
                  policy_number: params.policy_number,
                  status: params.status,
                })
              );
            }
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

// Detail Of Users
export const userDetailAction = (para) => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      const config = {
        params: {
          user_id: para,
        },
      };
      api.get("fetch/single_user", config).then(
        (response) => {
          let data = response.data.data;
          let responseStatus = response.data.code;

          if (responseStatus === 200) {
            dispatch({
              type: USERS_DETAIL,
              payload: data,
            });
            dispatch(loader(false));
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          console.log("error: ", error);
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

export const stateListAction = () => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api.get("fetch/states").then(
        (response) => {
          let data = response.data.data;
          let responseStatus = response.data.code;

          if (responseStatus === 200) {
            dispatch({
              type: STATE_LIST,
              payload: data,
            });
            dispatch(loader(false));
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

export const userExcelUploadActin = (para) => {
  return async (dispatch) => {
    dispatch(loader(true));
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await api.post("admin/uploadExcel", para, config).then(
        (response) => {
          if (response?.data?.code === 200) {
            console.log("here");
            message.success(response?.data?.message);
            dispatch(loader(false));
          }
        },
        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401 || resErr?.code === 500) {
            message.error(resErr?.message);
          }
          dispatch(loader(false));
        }
      );
    } catch {
      errorMessageConfig(dispatch);
    }
  };
};

export const UpdateRegisterUserAction = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_REGISTER_FORM_BUTTON_LOADING,
      payload: true,
    });

    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await api({
        method: "put",
        url: "user/updateRegisterForm/" + payload?.userId,
        data: payload?.formData,
        config,
      }).then(
        async (response) => {
          if (response?.data?.code === 200) {
            await dispatch({
              type: UPDATE_REGISTER_FORM_BUTTON_LOADING,
              payload: false,
            });

            await dispatch({
              type: UPDATE_REGISTER_USERS,
              payload: true,
            });
            await payload.navigate("/success/" + payload?.userId);
          }
        },
        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401 || resErr?.code === 500) {
            dispatch({
              type: UPDATE_REGISTER_USERS,
              payload: false,
            });
            dispatch({
              type: UPDATE_REGISTER_FORM_BUTTON_LOADING,
              payload: false,
            });
            dispatch(verifyCaptchaFalseAction());
            window.grecaptcha.reset();
            message.error(resErr?.message);
          }
        }
      );
    } catch (error) {
      let errMsg = error.response.data ? error.response.data : error.message;
      if (errMsg) {
        dispatch({
          type: UPDATE_REGISTER_USERS,
          payload: false,
        });
        dispatch({
          type: UPDATE_REGISTER_FORM_BUTTON_LOADING,
          payload: false,
        });
        message.error(errMsg && errMsg.message);
        window.grecaptcha.reset();
      }
    }
  };
};

export function printPdf(payload) {
  return async (dispatch) => {
    try {
      dispatch(pdfBtnLoader(true));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      api({
        method: "post",
        url: "user/downloadPdf",
        data: payload.formData2,
        config,
      }).then((response) => {
        if (response?.data?.code === 201) {
          let datas = response?.data?.data;
          dispatch({
            type: PRINT_DATA_STORE,
            payload: datas,
          });
          dispatch(pdfBtnLoader(false));
        }
      });
    } catch {
      dispatch(pdfBtnLoader(false));
    }
  };
}

export const verifyCaptchaAction = (params) => {
  return async (dispatch) => {
    try {
      await api.post("verifyCaptcha", params).then(
        async (response) => {
          if (response?.data?.code === 200) {
            await dispatch({
              type: VERIFY_CAPTCHA_UPDATE_USER,
              payload: true,
            });
          }
        },

        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401 || resErr?.code === 500) {
            message.error(resErr?.message);
            window.grecaptcha.reset();
          }
        }
      );
    } catch (error) {
      let resErr = error?.response?.data ? error?.response?.data : error;
      if (resErr?.code === 401 || resErr?.code === 500) {
        message.error(resErr?.message);
        window.grecaptcha.reset();
      }
    }
  };
};

// Set False captcha Action
export const verifyCaptchaFalseAction = () => {
  return {
    type: VERIFY_CAPTCHA_UPDATE_USER,
    payload: false,
  };
};

function errorMessageConfig(dispatch) {
  dispatch(loader(false));
  message.error("Something Went Wrong");
}

export const loader = (para) => {
  return {
    type: LOADER,
    payload: para,
  };
};

export const pdfBtnLoader = (para) => {
  return {
    type: PDF_BUTTTON_LOADER,
    payload: para,
  };
};

export const CholaButtonLoading = (para) => {
  return {
    type: CHOLA_BUTTON_LOADING,
    payload: para,
  };
};

export const EmdButtonLoading = (para) => {
  return {
    type: EMD_BUTTON_LOADING,
    payload: para,
  };
};

function ErrorMessage(error) {
  let resErr = error?.response?.data ? error?.response?.data : error;
  if (resErr?.code === 401 || resErr?.code === 500) {
    message.error(resErr?.message);
  }
}
