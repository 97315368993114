import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Row, Col } from "antd";
import { AllCountAction } from "../../Action/DashboardAction";
import Loader from "../../Component/Reusable/Loader/Loader";
import { Link } from "react-router-dom";

import {
  UsergroupAddOutlined,
  InfoCircleOutlined,
  SafetyCertificateOutlined,
  StopOutlined,
} from "@ant-design/icons";
import "./style.scss";

import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";

// State
const mapState = (props) => ({
  CountData: props?.DashboardReducer?.allCountData,
  SelectLoader: props?.DashboardReducer?.loaderState,
});

// Action
const mapDispatch = (dispatch) => {
  return {
    AllCount: () => dispatch(AllCountAction()),
  };
};

function Dashboard(store) {
  let totalCount = store?.CountData;

  let [TotalUser, setTotalUser] = useState(null);
  let [TotalPendingUser, setTotalPendingUser] = useState(null);
  let [TotalActiveUser, setTotalActiveUser] = useState(null);
  let [TotalReviewUser, setTotalReviewUser] = useState(null);
  let [TotalRejectedUser, setTotalRejectedUser] = useState(null);
  let [TotalInactiveUser, setTotalInactiveUser] = useState(null);

  useEffect(() => {
    store.AllCount();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Array Length Not Null
    if (totalCount.length !== 0) {
      // console.log("totalCount: ", totalCount);

      setTotalUser(totalCount[0].totalUser);
      setTotalActiveUser(totalCount[2]?.totalActiveUser);
      setTotalPendingUser(totalCount[5]?.totalPendingUser);
      setTotalReviewUser(totalCount[1]?.totalReviewUser);
      setTotalRejectedUser(totalCount[3]?.totalRejectedUser);
      setTotalInactiveUser(totalCount[4]?.totalInactiveUser);
    }
  }, [totalCount]);

  return (
    <SiteLayout>
      {store.SelectLoader === true && <Loader />}
      <div className="dashboard">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
            <Card
              title="Total Users"
              bordered={true}
              className="Card1 defaultCursor"
              hoverable
              extra={<UsergroupAddOutlined />}
            >
              All Users : {TotalUser}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
            <Link to="/users">
              <Card
                title={"Total Active Users"}
                bordered={true}
                className="Card2"
                hoverable
                extra={<SafetyCertificateOutlined />}
              >
                Active Users : {TotalActiveUser}
              </Card>
            </Link>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
            <Link to="/users">
              <Card
                title={"Total Inactive Users"}
                bordered={true}
                className="Card6"
                hoverable
                extra={<InfoCircleOutlined />}
              >
                Inactive Users : {TotalInactiveUser}
              </Card>
            </Link>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
            <Link to="/document">
              <Card
                title="Total Pending Users"
                bordered={true}
                className="Card3"
                hoverable
                extra={<InfoCircleOutlined />}
              >
                Pending Users : {TotalPendingUser}
              </Card>
            </Link>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
            <Link to="/document">
              <Card
                title={"Total Review Users"}
                bordered={true}
                className="Card5"
                hoverable
                extra={<InfoCircleOutlined />}
              >
                Review Users : {TotalReviewUser}
              </Card>
            </Link>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="pr0 pt10">
            <Link to="/document">
              <Card
                title={"Total Rejected Users"}
                bordered={true}
                className="Card4"
                hoverable
                extra={<StopOutlined />}
              >
                Rejected Users : {TotalRejectedUser}
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}
export default connect(mapState, mapDispatch)(Dashboard);
