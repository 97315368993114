import { LOADER, COUNT_USERS } from "./ActionTypes";
import api from "../Config/Api";

import { message } from "antd";

// Select All Count
export const AllCountAction = () => {
  return (dispatch) => {
    dispatch(loader(true));

    try {
      api.get("dashboard/statistics").then(
        (response) => {
          let data = response.data.data;
          let responseStatus = response.data.code;

          setTimeout(() => {
            if (responseStatus === 200) {
              dispatch({
                type: COUNT_USERS,
                payload: data,
              });
              dispatch(loader(false));
            } else {
              errorMessageConfig(dispatch);
            }
          }, 2000);
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

function errorMessageConfig(dispatch) {
  dispatch(loader(false));
  message.error("Something Went Wrong");
}

// Loader
export const loader = (para) => {
  return {
    type: LOADER,
    payload: para,
  };
};
