import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  Modal,
  Input,
  Button,
  Space,
  Tooltip,
  Tag,
  Row,
  Col,
} from "antd";
import {
  Pending_Users_List_Action,
  ChangeUserStatusAction,
} from "../../Action/ReviewDocument";
import {
  EyeOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { useNavigate } from "react-router-dom";
const { confirm } = Modal;

// State
const mapState = (props) => ({
  users: props?.DocumentReducer,
  SelectLoader: props?.DocumentReducer,
  messages: props?.DocumentReducer?.MessageState,
});

// Action
const mapDispatch = (dispatch) => {
  return {
    Pending_Users_List: (payload) =>
      dispatch(Pending_Users_List_Action(payload)),

    ChangeUserStatus: (payload, location_redirect, navigate) =>
      dispatch(ChangeUserStatusAction(payload, location_redirect, navigate)),
  };
};

function DocumentList(store) {
  let navigate = useNavigate();
  let { TextArea } = Input;
  let selectUsersArray = store?.users?.UsersArray;

  let [isRejectedModalVisible, setRejectedModalVisible] = useState(false);
  let [userId, setUserID] = useState("");
  let [userMessage, SetUserMessage] = useState("");
  let [List, setList] = useState([]);
  let [page, setPage] = useState(1);
  let [pageSize, setpageSize] = useState(10);
  let [totalCount, setTotalCount] = useState(0);
  let [clickSwitchID, setClickSwitchID] = useState(null);

  // Rejected
  const RejectedUser = (id) => {
    setUserID(id);
    setRejectedModalVisible(true);
  };

  // Active Model Show
  const ActiveUser = (id) => {
    setUserID(id);
    setRejectedModalVisible(false);
    confirm({
      title: "Are you sure you want to active this user?",
      icon: <QuestionCircleOutlined style={{ color: "#030d46" }} />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",

      async onOk() {
        setClickSwitchID(id);
        const payload = {
          pageNumber: page,
          nPerPage: pageSize,
          user_id: id,
          status: "ACTIVE",
        };
        await store?.ChangeUserStatus(payload, "document", navigate);
      },

      onCancel() {
        setClickSwitchID(null);
      },
    });
  };

  // Click Ok REJECTED
  const ClickOkRejected = async () => {
    setUserID(userId);
    setClickSwitchID(userId);

    const payload = {
      pageNumber: page,
      nPerPage: pageSize,
      user_id: userId,
      status: "REJECTED",
      message: userMessage,
    };

    await store?.ChangeUserStatus(payload, "document", navigate);
    setRejectedModalVisible(false);
    SetUserMessage("");
  };

  // Click Click
  const CancelClick = () => {
    SetUserMessage("");
    setRejectedModalVisible(false);
  };

  // Load Tale First Time
  useEffect(() => {
    let payload = {
      page,
      pageSize,
      status: "PENDING",
    };
    store.Pending_Users_List(payload);
    if (selectUsersArray !== undefined && selectUsersArray.length >= 0) {
      setList(selectUsersArray);
    }
    // eslint-disable-next-line
  }, []);

  // Update Tale
  useEffect(() => {
    if (selectUsersArray !== undefined && selectUsersArray.length >= 0) {
      setList(selectUsersArray);
      setTotalCount(store.users.UsersCount);
    }
  }, [selectUsersArray, store.users.UsersCount]);

  useEffect(() => {
    if (store?.messages) {
      // message.success(store?.messages);
      setClickSwitchID(null);
    }
    // eslint-disable-next-line
  }, [store?.messages]);

  function onChangeFunctionality(pagination, filters, sorter, extra) {
    setPage(pagination?.current);
    setpageSize(pagination?.pageSize);

    let payload = {
      page: pagination?.current,
      pageSize: pagination?.pageSize,
      status: "PENDING",
      fieldName: sorter?.field && sorter?.field,
      order: sorter?.order && sorter?.order,
    };
    store.Pending_Users_List(payload);
  }

  // Loop
  let dataSource =
    List &&
    List?.map((data, index) => ({
      key: index,
      id: data._id,
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      status: data.status,
      action: data?.action,
    }));

  // Colums
  let columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
    },

    {
      title: "Action",
      key: "action",
      render: (text, row) => (
        <Space size="middle">
          {row.status === "REVIEW" && (
            <>
              <Button
                type="primary"
                onClick={() => ActiveUser(row.id)}
                loading={
                  clickSwitchID === row.id &&
                  store?.SelectLoader?.isActiveLoader
                    ? true
                    : false
                }
              >
                ACTIVE
              </Button>
              <Button
                type="danger"
                onClick={() => RejectedUser(row.id)}
                loading={
                  clickSwitchID === row.id &&
                  store.SelectLoader?.isRejectedLoader
                    ? true
                    : false
                }
              >
                REJECT
              </Button>
            </>
          )}
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      render: (text, row) => (
        <Space size="middle">
          <Tag
            color={
              (["PENDING", "pending"].includes(row?.status) && "blue") ||
              (["REJECTED", "rejected"].includes(row?.status) && "red") ||
              (["REVIEW", "review"].includes(row?.status) && "gold")
            }
          >
            {row.status}
          </Tag>
        </Space>
      ),
    },

    {
      title: "View",
      render: (text, row) => (
        <Space size="middle">
          <Tooltip title="View Detail">
            <Link to={`/userdetail/${row.id}`}>
              <EyeOutlined style={{ fontSize: "28px", color: "#0a1f7c" }} />
            </Link>
          </Tooltip>

          {/* {row.status === "REVIEW" && ( */}
          <Tooltip title="Edit">
            <Link to={`/edit-users/${row.id}`}>
              <EditOutlined style={{ fontSize: "28px", color: "#0a1f7c" }} />
            </Link>
          </Tooltip>
          {/* )} */}
        </Space>
      ),
    },
  ];
  const isStoreLoading =
    store.SelectLoader?.isActiveLoader === true ||
    store.SelectLoader?.isRejectedLoader === true ||
    store.SelectLoader?.loaderState === true;

  return (
    <>
      <SiteLayout>
        {isStoreLoading === true && <Loader />}

        <Row>
          <Col span={24}>
            <h2> Review Document</h2>
          </Col>
        </Row>

        <Table
          className="document_list_table"
          columns={columns}
          dataSource={dataSource}
          onChange={onChangeFunctionality}
          pagination={{
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            total: totalCount,
            /*
            onChange: (page, pageSize, sorter, extra) => {
              console.log("extra: ", extra);
              console.log("sorter: ", sorter);
              setPage(page);
              setpageSize(pageSize);
              let payload = { page, pageSize, status: "pending" };
              store.Pending_Users_List(payload);
            },
            */
          }}
        />

        <Modal
          title="Are you sure you want to reject this user?"
          visible={isRejectedModalVisible}
          onOk={ClickOkRejected}
          onCancel={CancelClick}
          
        > Enter reject reason :
        <TextArea
            rows={4}
            placeholder=""
            value={userMessage}
            onChange={(e) => SetUserMessage(e.target.value)}
          />
        </Modal>
      </SiteLayout>
    </>
  );
}
export default connect(mapState, mapDispatch)(DocumentList);
