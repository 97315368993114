export const SiteConstants = {
  mobileNumberExcel: "90991 81001",
  gigSureLabel: "GigSURE",
  RecaptchaKey: "6Lf8ZrIeAAAAAJZtixCw8mo5nWrHFZN2LRF_ZNlB",
  NA: "N/A",
  ImagePdfFileAllowedMessage: "Only Allowed PDF , png , jpg , jpeg image",
  acceptOnlyFileType: "application/pdf,.png,.jpg,.jpeg", // ,.gif
  acceptImagePdfFileType: ["pdf", "png", "jpg", "jpeg"], // , "gif"
  acceptImageFileType: ["png", "jpg", "jpeg"],
  acceptSignatureFileType: ".png,.jpg,.jpeg",
  imageDisplayType: ["image/png", "image/jpg", "image/jpeg"],
  nullCheck: [
    "",
    "NULL",
    "Null",
    "null",
    "Undefined",
    "UNDEFINED",
    "undefined",
    null,
    undefined,
    "N/A",
    "NA",
  ],
};
