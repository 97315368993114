import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./404.css";
import SiteLayout from "../../../Component/Reusable/Layout/SiteLayout";

function PageNotFound() {
  return (
    <SiteLayout>
      <div className="divmain">
        <h1 className="h1">404</h1>
        <div className="cloak__wrapper">
          <div className="cloak__container">
            <div className="cloak"></div>
          </div>
        </div>
        <div className="info">
          <h2 className="h2">We can't find that page</h2>
          <Link to="/dashboard">
            <ArrowLeftOutlined /> Go Back Home
          </Link>
        </div>
      </div>
    </SiteLayout>
  );
}

export default PageNotFound;
