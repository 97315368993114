import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import {
  Switch,
  Space,
  Tooltip,
  message,
  Input,
  Button,
  Table,
  Row,
  Col,
  Modal,
  Upload,
  Form,
  Anchor,
} from "antd";

import { Link } from "react-router-dom";

import {
  AllUserSelect,
  ActiveInActiveAction,
  UserSearchAction,
  EmdExportAction,
  CholaExportAction,
  userExcelUploadActin,
} from "../../Action/UserAction";

import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  DownloadOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";

const { confirm } = Modal;

// State
const mapState = (props) => ({
  users: props?.UserReducer,
  SelectLoader: props?.UserReducer?.loaderState,
  messages: props?.UserReducer?.MessageState,
});

// Action
const mapDispatch = (dispatch) => {
  return {
    EmdExport: () => dispatch(EmdExportAction()),
    CholaExport: () => dispatch(CholaExportAction()),
    userSelect: (page, pageSize) => dispatch(AllUserSelect(page, pageSize)),
    userSearch: (payload) => dispatch(UserSearchAction(payload)),
    userExcelUpload: (payload) => dispatch(userExcelUploadActin(payload)),
    UserActiveInActive: (payload) => dispatch(ActiveInActiveAction(payload)),
  };
};

function Users(store) {
  const CholaBtnLoading = useSelector(
    (state) => state?.UserReducer?.cholaBtnLoading
  );

  const EmdBtnLoading = useSelector(
    (state) => state?.UserReducer?.emdBtnLoading
  );

  let selectUsersArray = store.users.UsersArray;
  let [List, setList] = useState([]);

  let [page, setPage] = useState(1);
  let [pageSize, setpageSize] = useState(10);
  let [totalCount, setTotalCount] = useState(0);
  let [clickSwitchID, setClickSwitchID] = useState(null);
  let [PolicyNumber, setPolicyNumber] = useState(null);
  let [Name, setName] = useState(null);
  let [Mobile, setMobile] = useState(null);
  let [activeInActive, setActiveInActive] = useState(null);
  let [activeInactiveForFilter, setActiveInactiveForFilter] = useState(null);

  let [excelFile, setExcelFile] = useState("");
  let [excelFileData, setExcelFileData] = useState("");
  let [ExcelPopupVisible, setExcelPopupVisible] = useState(false);

  const [form] = Form.useForm();
  const formRef = useRef();

  const [FilePathExcelDownload, SetFilePathExcelDownload] = useState(null);

  useEffect(() => {
    let baseURL = process.env.REACT_APP_API_ENDPOINT;

    let datas = baseURL + `GigSURE_import_sample.xlsx`;
    SetFilePathExcelDownload(datas);
  }, []);

  /*
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  */

  async function getData() {
    await store.userSelect(page, pageSize);
  }

  useEffect(() => {
    if (selectUsersArray !== undefined) {
      setList(selectUsersArray);
      setTotalCount(store.users.UsersCount);
      if (store.users.UsersCount <= (page - 1) * pageSize) {
        setPage(1);
      }
    }
    // eslint-disable-next-line
  }, [selectUsersArray]);

  // Search And Filter
  useEffect(() => {
    searchAndFilter();
    if (store.users.UsersCount) {
      setTotalCount(store.users.UsersCount);
    }
    // eslint-disable-next-line
  }, [PolicyNumber, Name, Mobile, activeInActive, page, pageSize]);

  useEffect(() => {
    if (store.messages !== undefined && store.messages !== "") {
      message.success(store.messages);
      setClickSwitchID(null);
    }
    // eslint-disable-next-line
  }, [store.messages]);

  function statusChanged(checked, user_id, page, pageSize) {
    let actionPerform = checked ? "ACTIVE" : "INACTIVE";

    confirm({
      title: `Are you sure you want to  ${actionPerform.toLowerCase()}  this user?`,
      icon: <QuestionCircleOutlined style={{ color: "#030d46" }} />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",

      async onOk() {
        setClickSwitchID(user_id);
        const payload = {
          user_id,
          actionPerform,
          pageNumber: page,
          nPerPage: pageSize,
          policy_number: PolicyNumber,
          name: Name,
          mobile: Mobile,
          status: activeInActive,
        };

        await store?.UserActiveInActive(payload);
      },

      onCancel() {
        setClickSwitchID(null);
      },
    });
  }

  // SET DATA
  function SetData(parms, ParaName) {
    if (ParaName === "policy_number") {
      setPolicyNumber(parms);
    }
    if (ParaName === "name") {
      setName(parms);
    }
    if (ParaName === "status") {
      setActiveInActive(parms);
    }
    if (ParaName === "mobile") {
      setMobile(parms);
    }
  }

  async function searchAndFilter() {
    let payload = {
      pageNumber: page,
      nPerPage: pageSize,
    };

    if (PolicyNumber) {
      payload["policy_number"] = PolicyNumber;
    }
    if (Name) {
      payload["name"] = Name;
    }

    if (activeInActive) {
      payload["status"] = activeInActive;
    }

    if (Mobile) {
      payload["mobile"] = Mobile;
    }

    if (!activeInActive && !Name && !Mobile && !PolicyNumber) {
      return await getData();
    } else {
      return await store.userSearch(payload);
    }
  }

  // Chola Excel On Click
  async function ExcelCholaOnClick() {
    await store?.CholaExport();
  }

  // Emd Excel On Click
  async function ExcelEmdOnClick() {
    await store?.EmdExport();
  }

  let dataSource =
    List &&
    List.map((data, index) => ({
      policy_number: data.policy_number,
      key: index,
      _id: data._id,
      name: data.name,
      email: data.email,
      mobile: data.mobile,

      status: (
        <Tooltip title={data.status} key={Math.random()}>
          <Switch
            key={data._id}
            // defaultChecked={data.status === "ACTIVE" || false}
            checked={data.status === "ACTIVE" || false}
            onChange={(checked) =>
              statusChanged(checked, data._id, page, pageSize)
            }
            loading={
              clickSwitchID === data._id && store.SelectLoader ? true : false
            }
          />
        </Tooltip>
      ),
    }));

  let columns = [
    {
      title: "User Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({ confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search User Name"
              onChange={(e) => {
                SetData(e.target.value, "name");
                confirm({ closeDropdown: false });
              }}
              name="name"
              value={Name}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name === value;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,
      filterDropdown: ({ confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Mobile Number"
              onChange={(e) => {
                SetData(e.target.value, "mobile");
                confirm({ closeDropdown: false });
              }}
              name="mobile"
              value={Mobile}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.mobile === value;
      },
    },

    {
      title: "Policy Number",
      dataIndex: "policy_number",
      sorter: (a, b) =>
        a.policy_number !== undefined &&
        a.policy_number.localeCompare(
          b.policy_number !== undefined && b.policy_number
        ),
      filterDropdown: ({ confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Policy Number"
              onChange={(e) => {
                SetData(e.target.value, "policy_number");
                confirm({ closeDropdown: false });
              }}
              name="PolicyNumber"
              value={PolicyNumber}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.policy_number === value;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterMultiple: false,
      filters: [
        { text: "INACTIVE", value: "INACTIVE" },
        { text: "ACTIVE", value: "ACTIVE" },
      ],
      filteredValue:
        activeInactiveForFilter && activeInactiveForFilter.status
          ? activeInactiveForFilter.status
          : null,
    },

    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View Detail">
            <Link to={`/userdetail/${record._id}`}>
              <EyeOutlined style={{ fontSize: "28px", color: "#0a1f7c" }} />
            </Link>
          </Tooltip>
          <Tooltip title="Edit">
            <Link to={`/edit-users/${record._id}`}>
              <EditOutlined style={{ fontSize: "28px", color: "#0a1f7c" }} />
            </Link>
          </Tooltip>
          <Tooltip title="Download">
            <Link to={`/download-user-data/${record._id}`}>
              <DownloadOutlined
                style={{ fontSize: "28px", color: "#0a1f7c" }}
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const ImportExcelUsers = () => {
    setExcelPopupVisible(true);
  };

  const CancelClickExcelModel = () => {
    setExcelPopupVisible(false);
    setExcelFileData("");
    form.resetFields();
  };

  const onSubmit = async (value) => {
    const formData = new FormData();
    formData.append("excelFile", excelFileData);
    await store.userExcelUpload(formData);
    form.resetFields();
    CancelClickExcelModel();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const dummyRequestExcelFileUpload = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function onChangeFunctionality(pagination, filters, sorter, extra) {
    setPage(pagination.current);
    setpageSize(pagination.pageSize);
    if (filters.status && filters.status.length > 0) {
      setActiveInactiveForFilter(filters);
      SetData(filters.status[0], "status");
    } else {
      setActiveInactiveForFilter(null);
      SetData(null, "status");
    }
  }

  function clearFilters() {
    setActiveInactiveForFilter(null);
    setPolicyNumber(null);
    setName(null);
    setActiveInActive(null);
    setMobile(null);
  }

  return (
    <SiteLayout>
      {store.SelectLoader === true && <Loader />}

      <Row>
        <Col span={12}>
          <h2>Users List</h2>
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <Space style={{ marginBottom: 0 }}>
            <Button
              onClick={clearFilters}
              type="primary"
              style={{
                marginBottom: 5,
              }}
            >
              Clear Search & filters
            </Button>

            <Button
              type="primary"
              style={{
                marginBottom: 5,
              }}
              onClick={() => ExcelEmdOnClick()}
              loading={EmdBtnLoading}
            >
              Export Data
            </Button>

            <Button
              type="primary"
              style={{
                marginBottom: 5,
              }}
              onClick={() => ExcelCholaOnClick()}
              loading={CholaBtnLoading}
            >
              Chola Excel Export
            </Button>

            <Button
              onClick={ImportExcelUsers}
              type="primary"
              style={{
                marginBottom: 5,
              }}
            >
              Import Users
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            className="user_list_table"
            columns={columns}
            dataSource={dataSource}
            onChange={onChangeFunctionality}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              total: totalCount,
            }}
          />
        </Col>
      </Row>

      <Modal
        title="Upload Excel"
        visible={ExcelPopupVisible}
        onOk={form.submit}
        onCancel={CancelClickExcelModel}
      >
        <Form form={form} onFinish={onSubmit} ref={formRef}>
          <Form.Item
            name="excelFile"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Excel file is required",
              },
              {
                message: "Only Allowed Excel file",
                validator: (_, value) => {
                  if (value !== undefined) {
                    let extensionCheck = value[0]?.name?.split(".");

                    if (
                      ["xlsx"]?.includes(
                        extensionCheck[extensionCheck?.length - 1]
                      )
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            className="formItemWithImagePreview"
          >
            <Upload
              maxCount={1}
              name="excelFile"
              accept={".xlsx"}
              customRequest={dummyRequestExcelFileUpload}
              beforeUpload={(file, fileList) => {
                const isExcel =
                  file.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                if (!isExcel) {
                  // message.error("You can only upload xlsx file!");
                  // setIsErrorfileValidation(true);
                }

                if (
                  file?.type &&
                  file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  setExcelFile(URL.createObjectURL(file));
                  setExcelFileData(fileList[0]);
                }
              }}
              onRemove={(file) => {
                if (file) {
                  setExcelFile("");
                  setExcelFileData("");
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Form>

        <div className="custom_link">
          <Anchor
            affix={false}
            style={{
              padding: "0px",
            }}
          >
            <Anchor.Link
              href={FilePathExcelDownload}
              title="Download sample excel file"
            />
          </Anchor>
        </div>
      </Modal>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(Users);
