import { message } from "antd";
import {
  COLLAPSED_MENU,
  LOADER,
  UPDATE_PASSWORD,
  BUTTON_LOADING,
  STORE_SETTING_DATA,
} from "../Action/ActionTypes";

import { LogoutAdmin } from "../Action/AdminAuth";

import api from "../Config/Api";

export const CollapsedMenuAction = (para) => {
  return {
    type: COLLAPSED_MENU,
    payload: para,
  };
};

export const GetSettingDataAction = () => {
  return async (dispatch) => {
    // dispatch(loading(true));

    try {
      await api.get("admin/setting").then(
        (resposne) => {
          if (resposne?.data?.code === 200) {
            // dispatch(loading(false));
            dispatch({
              type: STORE_SETTING_DATA,
              payload: resposne?.data,
            });
          }
        },
        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401) {
            message.error(resErr.message);
          }
          // dispatch(loading(false));
        }
      );
    } catch {
      console.log("catch error");
    }
  };
};

export const UpdatePasswordAction = (para, navigate) => {
  return async (dispatch) => {
    dispatch(button_loading(true));
    try {
      await api.put("admin/changePassword", para).then(
        (resposne) => {
          if (resposne?.data?.code === 201) {
            dispatch(button_loading(false));
            dispatch({
              type: UPDATE_PASSWORD,
              payload: resposne,
            });
            message.success(resposne?.data?.message);

            setTimeout(() => {
              dispatch(LogoutAdmin(navigate));
            }, 2000);
          }
        },
        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401) {
            dispatch({
              type: UPDATE_PASSWORD,
              payload: resErr,
            });
            message.error(resErr.message);
          }
          dispatch(button_loading(false));
        }
      );
    } catch {
      console.log("catch error");
    }
  };
};

export const UpdateSettingAction = (payload) => {
  return async (dispatch) => {
    dispatch(button_loading(true));
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await api({
        method: "post",
        url: "admin/setting",
        data: payload?.formData,
        config,
      }).then(
        (resposne) => {
          if (resposne?.data?.code === 201) {
            dispatch(button_loading(false));
            message.success(resposne?.data?.message);
          }
        },
        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401) {
            message.error(resErr.message);
          }
          dispatch(button_loading(false));
        }
      );
    } catch {
      console.log("catch error");
    }
  };
};

export const button_loading = (para) => {
  return {
    type: BUTTON_LOADING,
    payload: para,
  };
};

export const loading = (para) => {
  return {
    type: LOADER,
    payload: para,
  };
};
