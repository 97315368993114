import { Result } from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";

function Success() {
  const { id } = useParams();
  return (
    <SiteLayout>
      <div className="gig-registered-success-result successPage">
        <Result
          style={{ paddingBottom: "0px" }}
          status="success"
          title="The application updated successfully"
        />
        <div className="ant-result-subtitle">
          <Link to={`/userdetail/${id}`}>
            Click here to check the application
          </Link>
        </div>
      </div>
    </SiteLayout>
  );
}
export default Success;
