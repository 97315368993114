import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../../Dashboard/Dashboard";
import Users from "../../Users/Users";
import Setting from "../../Setting/Setting";
import UserDetail from "../../Users/UserDetail";
import RequireAuth from "../Auth/RequireAuth";
// import SiteLayout from "../Layout/SiteLayout";
import PageNotFound from "../404/PageNotFound";
import DocumentList from "../../Document/DocumentList";
import EditUsers from "../../Users/EditUsers";
import DownlaodUserData from "../../Users/DownlaodUserData";
import Success from "../../Users/Success";

const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});

function Routings(store) {
  return (
    <Fragment>
      {/* {store.CheckLogin === true && <SiteLayout />} */}

      <Routes>
        <Route exact path="/dashboard" element={<RequireAuth />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route exact path="/users" element={<RequireAuth />}>
          <Route exact path="/users" element={<Users />} />
        </Route>

        <Route exact path="/userdetail/:id" element={<RequireAuth />}>
          <Route exact path="/userdetail/:id" element={<UserDetail />}></Route>
        </Route>

        <Route exact path="/edit-users/:id" element={<RequireAuth />}>
          <Route exact path="/edit-users/:id" element={<EditUsers />}></Route>
        </Route>

        <Route exact path="/download-user-data/:id" element={<RequireAuth />}>
          <Route
            exact
            path="/download-user-data/:id"
            element={<DownlaodUserData />}
          ></Route>
        </Route>

        <Route exact path="/document" element={<RequireAuth />}>
          <Route exact path="/document" element={<DocumentList />} />
        </Route>

        <Route exact path="/setting" element={<RequireAuth />}>
          <Route exact path="/setting" element={<Setting />} />
        </Route>

        <Route exact path="/success/:id" element={<RequireAuth />}>
          <Route exact path="/success/:id" element={<Success />}></Route>
        </Route>

        <Route path="/" element={<Login />} exact />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Fragment>
  );
}

export default connect(mapState, null)(Routings);
