import { LOADER, COUNT_USERS } from "../Action/ActionTypes";

const initialState = {
  // All Stats
  allCountData: [],
  loaderState: true,
};

export default function DashboardReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADER:
      return { ...state, loaderState: payload };

    case COUNT_USERS:
      return { ...state, allCountData: payload };

    default:
      return state;
  }
}
