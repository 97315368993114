import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import "./login.scss";
import { LoginCheck } from "../../../Action/AdminAuth";
import logo from "../../../Assets/images/logo.png";
import { SiteConstants } from "../../../Config/SiteConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const mapDispatch = (dispatch) => {
  return {
    LoginDispatch: (username, password, navigate, tokenRecaptcha) =>
      dispatch(LoginCheck(username, password, navigate, tokenRecaptcha)),
  };
};

const mapState = (props) => ({
  CheckLogin: props.AuthReducer.isLoggedInAdminState,
});

function Login(store) {
  const [form] = Form.useForm();
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  const navigate = useNavigate();
  const reCaptchaRef = useRef();
  const { RecaptchaKey, gigSureLabel } = SiteConstants;

  const onFinish = () => {
    store.LoginDispatch(username, password, navigate, tokenRecaptcha);
  };

  const onFinishFailed = (errorInfo) => {};

  function onChange(value) {
    setTokenRecaptcha(value);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login - ${gigSureLabel}`}</title>
      </Helmet>

      {store.CheckLogin === true && <Navigate to="/dashboard" />}

      <div className="LoginPageMainClass">
        <div className="login-page">
          <div className="login-box">
            <div id="logoDiv">
              <img id="logo" src={logo} alt="Login" />
            </div>

            <Form
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              autoComplete="off"
            >
              <p className="form-title">Login</p>

              <Form.Item
                name="username"
                rules={[{ required: true, message: "Username is required" }]}
              >
                <Input
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  name="username"
                  value={username}
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  value={password}
                  type="password"
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                name="reCAPTCHA"
                rules={[
                  {
                    required: true,
                    message: "reCAPTCHA is required",
                  },
                ]}
              >
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={RecaptchaKey}
                  size="normal"
                  onChange={onChange}
                  onExpired={(e) => setTokenRecaptcha("")}
                  onErrored={(e) => setTokenRecaptcha("")}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    color: "#fff",
                    background: "#001F7C",
                    border: "#001F7C",
                  }}
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(mapState, mapDispatch)(Login);
