import Routings from "./Component/Reusable/Routing/Routings";

function App() {
  return (
    <div className="App">
      <Routings />
    </div>
  );
}

export default App;
