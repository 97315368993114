import { SiteConstants } from "./SiteConstants";
const { nullCheck, NA } = SiteConstants;
let insuranceArray = [];
let ownPropertyArray = [];

function customSplit(str, maxLength) {
  if (str.length <= maxLength) return str;
  var reg = new RegExp(".{1," + maxLength + "}", "g");
  var parts = str.match(reg);
  return parts.join("\n");
}
// For Edit Page
function NullTextCheckEditForm(params) {
  return nullCheck?.includes(params) ? "" : params;
}
// For Detail Page
function NullTxtChkDetailPage(params) {
  return nullCheck?.includes(params) ? NA : params || NA;
}
// For Detail Page
function insuranceFunction(insurance) {
  insuranceArray =
    insurance &&
    insurance?.split(",")?.map((element) => {
      if (["MEDICAL", "Medical", "MEDICAL INSURANCE"].includes(element)) {
        return "MEDICAL INSURANCE";
      } else if (["LIFE", "Life", "LIFE INSURANCE"].includes(element)) {
        return "LIFE INSURANCE";
      } else if (
        ["PA", "Pa", "PERSONAL ACCIDENT INSURANCE"].includes(element)
      ) {
        return "PERSONAL ACCIDENT INSURANCE";
      } else if (["NONE", "None", "NONE OF ABOVE"].includes(element)) {
        return "NONE";
      } else if (["ALL", "All", "ALL OF ABOVE"].includes(element)) {
        return "MEDICAL INSURANCE , LIFE INSURANCE , PERSONAL ACCIDENT INSURANCE";
      } else {
        return NA;
      }
    });
}

// For Detail Page
function OwnPropertyFunction(ownProperty) {
  ownPropertyArray =
    ownProperty &&
    ownProperty?.split(",")?.map((element) => {
      if (element === "ALL") {
        return "RESIDENTIAL PROPERTY , COMMERCIAL PROPERTY , LAND";
      } else if (element === "NONE") {
        return "NONE";
      } else if (element === "LAND") {
        return "LAND";
      } else if (element === "COMMERCIAL PROPERTY") {
        return "COMMERCIAL PROPERTY";
      } else if (element === "RESIDENTIAL PROPERTY") {
        return "RESIDENTIAL PROPERTY";
      } else {
        return NA;
      }
    });
}

export {
  customSplit,
  NullTextCheckEditForm,
  NullTxtChkDetailPage,
  insuranceFunction,
  insuranceArray,
  OwnPropertyFunction,
  ownPropertyArray,
};
