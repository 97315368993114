import {
  COLLAPSED_MENU,
  UPDATE_PASSWORD,
  BUTTON_LOADING,
  STORE_SETTING_DATA,
  LOADER,
} from "../Action/ActionTypes";

const initialState = {
  isCollapsedMenu: null,
  isPasswordUpdate: null,
  loaderState: true,
  pageloaderState: true,
  settingDataState: null,
};

export default function SiteSettingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case COLLAPSED_MENU:
      return { ...state, isCollapsedMenu: payload };

    case UPDATE_PASSWORD:
      return {
        ...state,
        isPasswordUpdate: payload,
      };
    case BUTTON_LOADING: {
      return {
        ...state,
        loaderState: payload,
      };
    }

    case LOADER: {
      return {
        ...state,
        pageloaderState: payload,
      };
    }

    case STORE_SETTING_DATA: {
      return {
        ...state,
        settingDataState: payload,
      };
    }
    default:
      return state;
  }
}
