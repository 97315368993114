// Admin Login
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

// Users
export const USERS_SELECT = "USERS_SELECT";
export const LOADER = "LOADER";
export const REFRESH = "REFRESH";
export const USER_UPDATE = "USER_UPDATE";
export const USERS_DETAIL = "USERS_DETAIL";
export const USER_SEARCH = "USER_SEARCH";
export const STATE_LIST = "STATE_LIST";
export const UPDATE_REGISTER_USERS = "UPDATE_REGISTER_USERS";
export const VERIFY_CAPTCHA_UPDATE_USER = "VERIFY_CAPTCHA_UPDATE_USER";
export const PRINT_DATA_STORE = "PRINT_DATA_STORE";
export const PDF_BUTTTON_LOADER = "PDF_BUTTTON_LOADER";
export const UPDATE_REGISTER_FORM_BUTTON_LOADING =
  "UPDATE_REGISTER_FORM_BUTTON_LOADING";
export const CHOLA_BUTTON_LOADING = "CHOLA_BUTTON_LOADING";
export const EMD_BUTTON_LOADING = "EMD_BUTTON_LOADING";
export const SAMPLE_EXCEL_FILE_BTN_LOADING = "SAMPLE_EXCEL_FILE_BTN_LOADING";

// Review Document Section
export const USERS_SELECT_INACTIVE = "USERS_SELECT_INACTIVE";

// Setting
export const UPDATE_SETTING_ERROR = "UPDATE_SETTING_ERROR";
export const UPDATE_SETTING = "UPDATE_SETTING";

// Dashboard
export const COUNT_USERS = "COUNT_USERS";

// Site Setting
export const COLLAPSED_MENU = "COLLAPSED_MENU";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const STORE_SETTING_DATA = "STORE_SETTING_DATA";
export const BUTTON_LOADING = "BUTTON_LOADING";
