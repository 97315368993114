import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Card, Upload, Tabs } from "antd";
import {
  UpdatePasswordAction,
  UpdateSettingAction,
  GetSettingDataAction,
} from "../../Action/SiteSetting";
import { UploadOutlined } from "@ant-design/icons";
import "../../Assets/Styles/main.css";
import "./style.scss";
import _ from "lodash";
// import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import NoImage from "../../Assets/images/NoImage.png";
import publicIp from "public-ip";
const { TextArea } = Input;

const mapDispatch = (dispatch) => {
  return {
    UpdatePassword: (para, navigate) =>
      dispatch(UpdatePasswordAction(para, navigate)),
    UpdateSetting: (payload) => dispatch(UpdateSettingAction(payload)),
    GetSettingData: () => dispatch(GetSettingDataAction()),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.SiteSettingReducer?.loaderState,
  isPasswordUpdate: props?.SiteSettingReducer?.isPasswordUpdate,
  settingData: props?.SiteSettingReducer?.settingDataState,
});

function Setting(store) {
  let navigate = useNavigate();
  const { TabPane } = Tabs;
  /* apiKeySms scretKeySms */

  const [QRImage, setQRImage] = useState("");
  const [QRImageData, setQRImageData] = useState("");

  const [isPdfFileUploaded, setIsPdfFileUploaded] = useState({});

  const [ipAddress, setIpAddress] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const [QrImageValidation, setQrImageValidation] = useState(false);

  const [form1] = Form.useForm(); // form 1
  const [form] = Form.useForm(); // form 2

  useEffect(() => {
    (async () => {
      const ipV4Address = await publicIp.v4();
      setIpAddress(ipV4Address);
    })();
  }, []);

  useEffect(() => {
    if (store?.settingData) {
      form1.setFieldsValue({
        account_name: store?.settingData?.data?.account_name,
        account_no: store?.settingData?.data?.account_no,
        bank_name: store?.settingData?.data?.bank_name,
        branch_name: store?.settingData?.data?.branch_name,
        branch_code: store?.settingData?.data?.branch_code,
        upi_id: store?.settingData?.data?.upi_id,
        ifsc_code: store?.settingData?.data?.ifsc_code,
        qr_code_image: setQRImage(store?.settingData?.data?.qr_code_image),
      });
    }
    //eslint-disable-next-line
  }, [store?.settingData]);

  useEffect(() => {
    if (store?.settingData?.data?.qr_code_image) {
      setQrImageValidation(false);
    } else {
      setQrImageValidation(true);
    }
  }, [store?.settingData?.data?.qr_code_image]);

  useEffect(() => {
    (async () => {
      await store?.GetSettingData();
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store?.isPasswordUpdate) {
      if (store?.isPasswordUpdate?.data?.code === 201) {
        form.resetFields();
      }
    }
    //eslint-disable-next-line
  }, [store.isPasswordUpdate]);

  // Update Password
  const onUpdatePassword = (params) => {
    let payload = {
      newPassword: params?.newPassword,
      oldPassword: params?.oldPassword,
    };

    setIsLoadingBtn(true);
    store?.UpdatePassword(payload, navigate);
  };

  const onUpdateFailPassword = (params) => {
    console.log("params: ", params);
  };

  // Setting Update
  const onUpdateSetting = (params) => {
    const formData = new FormData();
    formData.append("ip", ipAddress);

    if (QRImageData) {
      formData.append("qr_code_image", QRImageData);
    }

    formData.append("account_name", params?.account_name);
    formData.append("account_no", params?.account_no);
    formData.append("ifsc_code", params?.ifsc_code);
    formData.append("branch_code", params?.branch_code);
    formData.append("bank_name", params?.bank_name);
    formData.append("branch_name", params?.branch_name);
    formData.append("upi_id", params?.upi_id);
    store?.UpdateSetting({ formData, navigate });
    setIsLoadingBtn(true);
  };

  const onUpdateFailSetting = (params) => {
    console.log("params: ", params);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <SiteLayout>
      <div className="setting_class">
        <Tabs type="card">
          <TabPane tab="Profile Setting" key="1">
            <Row>
              <Col xs={24} xl={6}></Col>
              <Col xs={24} xl={13}>
                <div style={{ padding: "20px" }}>
                  <Card id="hoverable">
                    <h1 className="center">Bank Details</h1>

                    <Form
                      form={form1}
                      name="updatepassword"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 12,
                      }}
                      onFinish={onUpdateSetting}
                      onFinishFailed={onUpdateFailSetting}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Account name"
                        name="account_name"
                        rules={[
                          {
                            required: true,
                            message: "Account name is required",
                          },
                          {
                            pattern: /^([^0-9]*)$/,
                            message: "Please insert valid account name",
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="Account number"
                        name="account_no"
                        rules={[
                          {
                            required: true,
                            message: "Account number is required",
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: "Value should contain just number",
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="IFSC Code"
                        name="ifsc_code"
                        rules={[
                          {
                            required: true,
                            message: "IFSC Code is required",
                          },
                          // {
                          //   pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                          //   message: "IFSC Code is Not Valid",
                          // },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="Bank name"
                        name="bank_name"
                        rules={[
                          {
                            required: true,
                            message: "Bank name is required",
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="Branch code"
                        name="branch_code"
                        rules={[
                          {
                            required: true,
                            message: "Branch code is required",
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="Branch name"
                        name="branch_name"
                        rules={[
                          {
                            required: true,
                            message: "Branch name is required",
                          },
                        ]}
                      >
                        {/* <Input autoComplete="off" /> */}
                        <TextArea rows={3} />
                      </Form.Item>

                      <Form.Item
                        label="UPI ID"
                        name="upi_id"
                        rules={[
                          {
                            required: true,
                            message: "UPI ID is required",
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        name="qr_code_image"
                        label="Upload QR code image"
                        getValueFromEvent={normFile}
                        rules={[
                          {
                            required: QrImageValidation,
                            message: "QR code image is required",
                          },
                          {
                            message: "Only Allowed png , jpg , jpeg images",
                            validator: (_, value) => {
                              if (value) {
                                let extensionCheck = value[0]?.name?.split(".");
                                if (
                                  ["png", "jpg", "jpeg"]?.includes(
                                    extensionCheck[extensionCheck?.length - 1]
                                  )
                                ) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject("Some message here");
                                }
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                        className="formItemWithImagePreview"
                      >
                        <Upload
                          maxCount={1}
                          name="qr_code_image"
                          accept=".png,.jpg,.jpeg"
                          customRequest={dummyRequest}
                          beforeUpload={(file, fileList) => {
                            setQRImage(URL.createObjectURL(file));
                            setQRImageData(fileList[0]);
                          }}
                          onRemove={(file) => {
                            if (file) {
                              setQRImage("");
                              setQRImageData("");
                            }
                          }}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </Upload>
                      </Form.Item>
                      {QRImage && (
                        <div style={{ textAlign: "center" }}>
                          {isPdfFileUploaded &&
                          _.has(isPdfFileUploaded, "qr_code_image") &&
                          isPdfFileUploaded.qr_code_image ? (
                            <img className="QRImage" src={NoImage} alt="" />
                          ) : (
                            <img
                              className="QRImage"
                              src={QRImage}
                              alt=""
                              // onError={(e) => {
                              //   // e.currentTarget.src = NoImage;
                              // }}
                            />
                          )}
                        </div>
                      )}
                      <br />
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={
                            isLoadingBtn === false
                              ? isLoadingBtn
                              : store?.SelectLoader
                          }
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Change Password" key="2">
            <Row>
              <Col xs={24} xl={6}></Col>
              <Col xs={24} xl={12}>
                <div style={{ padding: "20px" }}>
                  <Card id="hoverable">
                    <h1 className="center"> Change Password</h1>
                    <Form
                      form={form}
                      name="updatepassword"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 12,
                      }}
                      onFinish={onUpdatePassword}
                      onFinishFailed={onUpdateFailPassword}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Current password"
                        name="oldPassword"
                        rules={[
                          {
                            required: true,
                            message: "Current password is required",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        label="New password"
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "New password is required",
                          },
                          {
                            min: 6,
                            message: "Password should be at least 6 characters",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        label="Confirm password"
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: "Confirm password is required",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue("newPassword") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                "New password and confirm password does not match!"
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={
                            isLoadingBtn === false
                              ? isLoadingBtn
                              : store?.SelectLoader
                          }
                        >
                          Update Password
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </div>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(Setting);
