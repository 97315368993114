import {
  USERS_SELECT_INACTIVE,
  LOADER,
  REFRESH,
  USER_UPDATE,
} from "../Action/ActionTypes";

import api from "../Config/Api";
import { message } from "antd";

// Select List
export const Pending_Users_List_Action = (params) => {
  let parameter = {
    pageNumber: params?.page ? params?.page : params?.pageNumber,
    nPerPage: params?.pageSize ? params?.pageSize : params?.nPerPage,
    status: "PENDING",
  };

  if (params?.fieldName) {
    parameter["fieldName"] = params?.fieldName;
  }

  if (params?.order) {
    parameter["order"] = params?.order;
  }

  return (dispatch) => {
    dispatch(loader({ loaderState: true }));

    try {
      api({
        url: "admin/inactive/user",
        method: "get",
        data: parameter,
        params: parameter,
      }).then(
        (response) => {
          let data = response.data.data.list;
          let responseStatus = response.data.code;
          let count = response.data.data.count;

          let params = {
            count,
            data,
          };

          if (responseStatus === 200) {
            dispatch({
              type: USERS_SELECT_INACTIVE,
              payload: params,
            });

            dispatch(loader({ loaderState: false }));
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

// <<<-------   ( ACTIVE / REJECTED ) IN DOCUMENT SECTION
export const ChangeUserStatusAction = (params, location_redirect, navigate) => {
  return (dispatch) => {
    dispatch(
      loader({
        isActiveLoader: params.status === "ACTIVE" ? true : false,
        isRejectedLoader: params.status === "REJECTED" ? true : false,
      })
    );

    try {
      api({
        url: "admin/user/status",
        method: "post",
        data: params,
        params: params,
      }).then(
        (response) => {
          let responseStatus = response.data.code;
          let msg = response.data.message;

          if (responseStatus === 200) {
            dispatch({
              type: USER_UPDATE,
              payload: msg,
            });

            let parameter = {
              pageNumber: params.pageNumber,
              nPerPage: params.nPerPage,
              status: "PENDING",
            };

            if (location_redirect === "userdetail") {
              // window.location.replace("/userdetail/" + params.user_id);
              navigate("/userdetail/" + params?.user_id);
              dispatch(refresh(true));
              message.success(msg);
            } else {
              dispatch(Pending_Users_List_Action(parameter));
              dispatch(loader(false));
              dispatch(
                loader({
                  isActiveLoader: params.status === "ACTIVE" ? false : true,
                  isRejectedLoader: params.status === "REJECTED" ? false : true,
                })
              );
              message.success(msg);
            }
          } else {
            errorMessageConfig(dispatch);
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

function errorMessageConfig(dispatch) {
  // dispatch(loader(false));
  dispatch(
    loader({
      loaderState: false,
      isActiveLoader: false,
      isRejectedLoader: false,
    })
  );
  message.error("Something Went Wrong");
}

export const loader = (para) => {
  return {
    type: LOADER,
    payload: para,
  };
};

export const refresh = (para) => {
  return {
    type: REFRESH,
    payload: para,
  };
};
