import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

console.log(process.env.REACT_APP_CUSTOM_NODE_ENV, "<----");
console.log(process.env.REACT_APP_API_ENDPOINT, "<----");

api.interceptors.request.use(
  function (config) {
    if (config.url !== "admin/login") {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem("Bearer")
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default api;
