import {
  USERS_SELECT_INACTIVE,
  LOADER,
  USERS_DETAIL,
  USER_UPDATE,
} from "../Action/ActionTypes";

const initialState = {
  // All Stats
  UsersCount: 0,
  UsersArray: [],
  MessageState: "",
  loaderState: true,
  isActiveLoader: false,
  isRejectedLoader: false,
  SingelUser: [],
};

export default function DocumentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADER:
      return { ...payload };

    case USERS_SELECT_INACTIVE:
      return { ...state, UsersArray: payload.data, UsersCount: payload.count };

    case USERS_DETAIL:
      return { ...state, SingelUser: payload };

    case USER_UPDATE:
      return { ...state, MessageState: payload };

    default:
      return state;
  }
}
