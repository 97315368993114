import {
  USERS_SELECT,
  LOADER,
  USERS_DETAIL,
  USER_UPDATE,
  USER_SEARCH,
  STATE_LIST,
  UPDATE_REGISTER_USERS,
  VERIFY_CAPTCHA_UPDATE_USER,
  PRINT_DATA_STORE,
  PDF_BUTTTON_LOADER,
  REFRESH,
  CHOLA_BUTTON_LOADING,
  EMD_BUTTON_LOADING,
  SAMPLE_EXCEL_FILE_BTN_LOADING,
  UPDATE_REGISTER_FORM_BUTTON_LOADING,
} from "../Action/ActionTypes";

const initialState = {
  UsersArray: [],
  UsersCount: 0,
  MessageState: "",
  loaderState: true,
  SingelUser: [],
  StateListArray: [],
  refreshState: false,
  cholaBtnLoading: false,
  emdBtnLoading: false,

  pdfData: null,
  printLoader: null,
  VerifycaptchaUpdateUser: false,
  UpdateUserButtonLoading: false, // Update Form Click loading
  UpdateRegisterUserState: null, // Toggel Check Is it User Update Or Not
};

export default function UserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADER:
      return { loaderState: payload };

    case REFRESH:
      return { refreshState: payload };

    case USERS_SELECT:
      return { ...state, UsersArray: payload.data, UsersCount: payload.count };

    case USER_SEARCH:
      return { ...state, UsersArray: payload.data, UsersCount: payload.count };

    case USERS_DETAIL:
      return { ...state, SingelUser: payload };

    case USER_UPDATE:
      return { ...state, MessageState: payload };

    case UPDATE_REGISTER_USERS:
      return { ...state, UpdateRegisterUserState: payload };

    case VERIFY_CAPTCHA_UPDATE_USER:
      return { ...state, VerifycaptchaUpdateUser: payload };

    case STATE_LIST:
      return { ...state, StateListArray: payload };

    case PRINT_DATA_STORE:
      return {
        ...state,
        pdfData: payload,
      };

    case PDF_BUTTTON_LOADER:
      return {
        ...state,
        printLoader: payload,
      };

    case CHOLA_BUTTON_LOADING:
      return {
        ...state,
        cholaBtnLoading: payload,
      };

    case EMD_BUTTON_LOADING:
      return {
        ...state,
        emdBtnLoading: payload,
      };

    case UPDATE_REGISTER_FORM_BUTTON_LOADING:
      return {
        ...state,
        UpdateUserButtonLoading: payload,
      };

    default:
      return state;
  }
}
